
function submitLogger(message, source) {

	var log_url = location.href.indexOf('dev.') !== -1 || location.href.indexOf('staging.') !== -1 || location.href.indexOf('.loc') !== -1 || location.href.indexOf('localhost') !== -1 ? 'https://devlog.alternativemedia.com.au/log/lead' : 'https://log.alternativemedia.com.au/log/lead';

	$.ajax({
		url: log_url,
		dataType: 'json',
		type: 'POST',
		data: {
			message,
			type:'lead submitted',
			source
		},
		success: function(data) {
			if(data.success)
				console.log(data);

			sessionStorage.clear();
		},
		error: function(e) {
			console.log(e);
			sessionStorage.clear();
		}
	});

}

// get the values on the checkbox
function getCheckedVal(name) {

	let result = '',
		selected = [];

	$(`input[name="${name}"]:checked`).each(function(){
		selected.push($(this).val());
	});

	result = selected.join(', ');

	return result;

}

function setSessionData(form) {

	let formData = {},
		current_session = sessionStorage.getItem('formData') !== null ? JSON.parse(sessionStorage.getItem('formData')) : {},
		stringData = '';

	if(typeof form !== 'undefined')
		formData = form.serializeObject();

	for(var item in formData) {
		if(formData[item] == '') 
			delete formData[item];
	}

	formData = {...current_session, ...formData};

	$('input[data-original-name], select[data-original-name]').each(function(){

		var el = $(this),
			el_orig_name = typeof el.attr('data-original-name') !== 'undefined' ? el.attr('data-original-name') : '',
			el_placeholder = typeof el.attr('data-placeholder') !== 'undefined' ? el.attr('data-placeholder') : '',
			current_name = typeof el.attr('name') !== 'undefined' ? el.attr('name') : '';

		if(current_name !== '' && el_orig_name !== '') {
			formData[el_orig_name] = formData[current_name];
		  	delete formData[current_name];
		}

		if(el.val() !== '' && el.val() == el_placeholder && el_orig_name !== '')
			delete formData[el_orig_name];

	});

	stringData = JSON.stringify(formData);
	
	sessionStorage.setItem('formData', stringData);

	return stringData;

}


// set session value
jQuery(function($){

	$.fn.serializeObject = function()
    {
      var o = {};
      var a = this.serializeArray();
      $.each(a, function() {
        if (o[this.name] !== undefined) {
          if (!o[this.name].push) {
            o[this.name] = [o[this.name]];
          }
          o[this.name].push(this.value || '');
        } else {
          o[this.name] = this.value || '';
        }
      });
      return o;
    };

	// set session value on input
	$('input, select').each(function(){

		let el = $(this);

		el.on('change input blur', function(){

			let el_name = typeof el.attr('name') !== 'undefined' ? el.attr('name') : '',
				el_type = el.attr('type'),
				el_orig_name = typeof el.attr('data-original-name') !== 'undefined' ? el.attr('data-original-name') : '',
				el_name_use = el_orig_name !== '' ? el_orig_name : el_name,
				el_placeholder = typeof el.attr('data-placeholder') !== 'undefined' ? el.attr('data-placeholder') : '',
				current_session = sessionStorage.getItem('formData') !== null ? JSON.parse(sessionStorage.getItem('formData')) : {};

			if(el_name_use !== '') {
				
				if(el_type == 'checkbox') {
					current_session[el_name_use] = getCheckedVal(el_name_use);

				} else {

					if(el.val() !== '' && el.val() !== el_placeholder)
						current_session[el_name_use] = el.val();
				}
				
				sessionStorage.setItem('formData', JSON.stringify(current_session));
			}
	
		});
	
	});


});
